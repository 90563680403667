/**
 * Embedded
 */

import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import EmbedMessage from 'Components/EmbedMessage';
import s from './Embedded.module.scss';

const Embedded = ({
    html = '',
    tag = '',
    title = '',
    height = '',
    center = false,
    fullWidth = false,
}) => {

    const htmlRef = useRef();

    const matches = html.match(/<iframe[^>]*src=["|']([^'"]+)["|'][^>]*>/);
    let url = matches && matches.length > 1 ? matches[1] : '';

    const isYoutube = url.includes('youtube');
    const isSpotify = url.includes('spotify');
    const isTypeform = html.includes('form.typeform.com');

    if(isTypeform) {
        const typeformMatches = html.match(/<div[^>]*data-url=["|']([^'"]+)["|'][^>]*>/);
        url = typeformMatches && typeformMatches.length > 1 ? typeformMatches[1] : '';
    }

    const [showMsg, setShowMsg] = useState(isYoutube || isSpotify || isTypeform);

    useEffect(() => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const javascript = doc.body?.querySelector('script');

        // Get js from html and append correctly, otherwise it won't be triggered
        const addScript = () => {
            const script = document.createElement('script');
            if(javascript) {
                script.innerHTML = javascript.innerHTML;
                htmlRef.current.appendChild(script);
                return () => {
                    htmlRef.removeChild(script);
                };
            }
        };

        if(!showMsg && javascript) {
            addScript();
        }
    }, [showMsg, htmlRef]);

    const hasHeader = Boolean(tag || title);

    const category = isTypeform ? 'performance' : (
        isSpotify ? 'functionality' : (
            isYoutube ? 'targeting' : ''
        )
    );

    const classes = classNames(
        s['Embedded'],
        {[s['Embedded--FullWidth']]: fullWidth},
        {[s['Embedded--Center']]: center},
    );

    return (
        <div className={classes}>
            {hasHeader && (
                <div className={s['Embedded__Header']}>
                    {tag && <span className={s['Embedded__Tag']}>{tag}</span>}
                    {title && <h2 className={s['Embedded__Title']}>{title}</h2>}
                </div>
            )}

            {showMsg && html &&
                <div className={s['Embedded__Message']}>
                    <EmbedMessage
                        src={url}
                        hideMessage={() => setShowMsg(false)}
                        category={category}
                    />
                </div>
            }

            {!showMsg && html && (
                <div
                    className={s['Embedded__Content']}
                    style={{ height: height }}
                    ref={htmlRef}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            )}
        </div>
    );
};

export default Embedded;
